
<i18n locale="th" lang="yaml" >
  page.title : "พบข้อผิดพลาดในระบบ"
  error.unexpected : "พบข้อผิดพลาดในระบบ"
</i18n>

<template>
  <ErrorPage
    :code-description="$t('error.unexpected')"
    :location="location"
    :error-detail="errorDetail"
    :show-refresh="true">
    <span slot="code">
      err<span class="text-error text-bold">0</span>r
    </span>
  </ErrorPage>
</template>

<script>
import ErrorPage from "@components/ErrorPage.vue"
import ApiError from '@utils/errors/ApiError'

export default {
  page() {
    return {
      title : this.$t('page.title')
    }
  } ,
  components : {
    ErrorPage,
  } ,
  props : {
    error : {
      type : null,
      default : null
    } ,
    location : {
      type : String,
      default : null
    }
  } ,
  computed: {
    errorDetail() {
      if (!this.error) {
        return false
      }
      if (this.error instanceof ApiError) {
        return this.error.getErrorTitle()
      } else if (this.error instanceof Error) {
        return this.error.message
      }
      return false
    }
  },

}
</script>
