<i18n locale="th" lang="yaml" >
 page.title : "กำลังทำนะ"
 error.under_construction : "หน้าจอนี้อยู่ระหว่างการพัฒนา"
</i18n>

<template>
  <ErrorPage
    class="error-container-under"
    :code-description="$t('error.under_construction')"
    :location="location">
    <span slot="code">
      <span v-show="false">
      N<span class="text-error text-bold">0</span>t Ready
      </span>
    </span>
  </ErrorPage>
</template>

<script>
import ErrorPage from "@components/ErrorPage.vue"
export default {
  page() {
    return {
      title : this.$t('page.title')
    }
  } ,
  components : {
    ErrorPage,
  } ,
  props : {
    location : {
      type : String,
      default : null
    }
  } ,
}
</script>
<style lang="less" scoped>
.error-container-under::v-deep .error-code {
  font-size : 4em;
}
</style>
