
<i18n locale="th" lang="yaml" >
 page.title : "ไม่มีสิทธิ์ใช้งาน"

 error.forbidden : "คุณไม่มีสิทธิ์ใช้งานหน้าจอ หรือ การทำงานนี้"

 to.home : "กลับหน้าแรก"
 to.login : "Login"
</i18n>

<template>
  <ErrorPage
    :code-description="$t('error.forbidden')"
    :location="location"
    :error-detail="errorDetail">
    <span slot="code">
      4<span class="text-error text-bold">0</span>3
    </span>
  </ErrorPage>
</template>

<script>
import ErrorPage from "@components/ErrorPage.vue"
export default {
  page() {
    return {
      title : this.$t('page.title')
    }
  } ,
  components : {
    ErrorPage,
  } ,
  props : {
    error : {
      type : null,
      default : null
    } ,
    message : {
      type : String,
      default : null,
    } ,
    location : {
      type : String,
      default : null
    }
  } ,
  computed : {
    errorDetail() {
      if (this.$notEmpty(this.message)){
        return this.message
      } else if (this.error && this.$notEmpty(this.error.message)) {
        return this.error.message
      } else {
        return null
      }
    }
  } ,
}
</script>
